var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Store_wrapper" },
    [
      _c("el-alert", {
        staticClass: "mb20",
        attrs: {
          title: "该页面仅展示有逛店记录的用户",
          type: "info",
          closable: false
        }
      }),
      _c(
        "div",
        { staticClass: "select_wrap" },
        [
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入ID：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入ID搜索 " },
                model: {
                  value: _vm.GoShopInfo.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.GoShopInfo, "userId", $$v)
                  },
                  expression: "GoShopInfo.userId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入名称：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入姓名搜索 " },
                model: {
                  value: _vm.GoShopInfo.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.GoShopInfo, "userName", $$v)
                  },
                  expression: "GoShopInfo.userName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入手机号码：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入手机号搜索 " },
                model: {
                  value: _vm.GoShopInfo.userMobile,
                  callback: function($$v) {
                    _vm.$set(_vm.GoShopInfo, "userMobile", $$v)
                  },
                  expression: "GoShopInfo.userMobile"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("完成状态：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.GoShopInfo.completeStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.GoShopInfo, "completeStatus", $$v)
                    },
                    expression: "GoShopInfo.completeStatus"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10 mt10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10 mt10",
              attrs: { type: "success", icon: "el-icon-download" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.GoShopList, border: "", stripe: "", size: "mini" }
        },
        [
          _c("el-table-column", { attrs: { prop: "userId", label: "客户ID" } }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "客户昵称" }
          }),
          _c("el-table-column", { attrs: { prop: "userName", label: "姓名" } }),
          _c("el-table-column", {
            attrs: { prop: "userMobile", label: "手机号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "helpProgress", label: "逛店进度" }
          }),
          _c("el-table-column", {
            attrs: { prop: "completeStatus", label: "完成状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.completeStatus == 0
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("未完成")
                        ])
                      : _vm._e(),
                    scope.row.completeStatus == 1
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("已完成")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "startTime", label: "首次逛店时间" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.GoShopInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.GoShopTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }